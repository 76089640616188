<template>
  <v-app>
    <v-container
      class="spacing-playground container__maxWidth"
      :class="{'pa-15': $vuetify.breakpoint.smAndUp}"
    >
      <div class="elevation-10"> 
        <v-app-bar flat class="rounded-t-lg" style="height: 70px">
          <template>
            <v-tabs icons-and-text 
                    :align-with-title="$vuetify.breakpoint.smAndUp"  
                    :fixed-tabs="$vuetify.breakpoint.xsOnly" 
                    v-model="tab"
                    style="width: 80%">
              <v-tab href="#home"> 
                home
                <v-icon>mdi-home</v-icon> 
              </v-tab>
              <v-tab href="#art"> 
                art
                <v-icon>mdi-video-vintage</v-icon> 
              </v-tab>
              <v-tab href="#about"> 
                about
                <v-icon>mdi-account</v-icon> 
              </v-tab>
            </v-tabs>
            <div class="text-h5 text-inner-shadow" v-if="$vuetify.breakpoint.smAndUp">
              <span :class="{ 'text-transparent': !(month <= 2 || month == 12) }">❄️ </span>
              <span :class="{ 'text-transparent': !(month >= 3 && month < 6) }">🌱 </span>
              <span :class="{ 'text-transparent': !(month >= 6 && month < 9) }">🌻 </span>
              <span :class="{ 'text-transparent': !(month >= 9 && month < 12) }">🍂 </span>
            </div>
          </template>
        </v-app-bar>

        <v-main>
          <v-tabs-items v-model="tab">
            <v-tab-item value="art">
              <div class="text-center helpbox__container my-2">
                <a href="#" class="helpbox">
                  <v-icon class="text-h3 grey--text">mdi-help-box</v-icon>
                </a>
                <div class="helpbox__description">
                  <div class="text-h5">procedural videos</div> <v-divider></v-divider>
                  <div class="helpbox__paragraph"> 
                    videos procedurally generated by math in fragment shaders (using either OpenGL ES 3.0 via WebGL or MSL via Apple's Metal) 
                    <br /> <br />
                    audio pieced together from field recordings
                  </div>
                </div>
              </div>
              <div class="grid">
                <a target="_blank" href="https://youtu.be/MZFCyl8PQQo" class="grid__item" tabindex="1">
                  <v-img
                  class="fill-height"
                  src="./assets/sound.png">
                  </v-img> 
                </a>

                <a target="_blank" href="https://youtu.be/z1r47rU_yA8" class="grid__item" tabindex="2">
                  <v-img
                    class="fill-height"
                    src="./assets/look.png">
                  </v-img> 
                </a>

                <a target="_blank" href="https://youtu.be/AZEjhexDr3I" class="grid__item" tabindex="3">
                  <v-img
                    class="fill-height"
                    src="./assets/out.png">
                  </v-img> 
                </a>
              </div>

              <div class="grid">
                <a target="_blank" href="https://vimeo.com/548509429" class="grid__item" tabindex="4">
                  <v-img
                    class="fill-height"
                    src="./assets/lux.png">
                  </v-img> 
                </a>
                <a target="_blank" href="https://youtu.be/BUo2qh0CX8Q" class="grid__item" tabindex="5">
                  <v-img
                    class="fill-height"
                    src="./assets/rolling.png">
                  </v-img> 
                </a>         
                <a target="_blank" href="https://youtu.be/Af4lLzKZvgY" class="grid__item" tabindex="6">
                  <v-img
                    class="fill-height"
                    src="./assets/torpor.png">
                  </v-img> 
                </a>
              </div>
            </v-tab-item>
            <v-tab-item value="home">
              <WebGL/>
            </v-tab-item>
            <v-tab-item value="about">
              <v-container fluid class="text-center mt-5">
                <v-row class="justify-center">
                  <v-col class="col-9">
                    <v-avatar size="225">
                      <v-img src="./assets/me.jpg" class="rounded-circle">
                      </v-img>
                    </v-avatar>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col class="col-9 text-h4">
                    Garrison McMullen
                  </v-col>
                  <v-col class="col-9 text-h5 pt-0 pb-0">
                    Programmer Writer
                  </v-col>
                  <v-col class="col-9 text-h6 pt-0 pb-0">
                    <a target="_blank" href="https://raymarchingfundamentals.org/">Raymarching Fundamentals</a>
                  </v-col>
                  <v-col class="col-9 text-h6 pt-0 pb-0">
                    <a target="_blank" href="https://www.philterms.org">Philosophy Terms</a>
                  </v-col>
                  <v-col class="col-9">
                    based in NYC
                  </v-col>
                </v-row>
                <v-row class="my-7">
                  <div class="ml-auto">
                    <v-btn
                      href="https://www.youtube.com/channel/UCcZYyXLJ7yEH3lWm58RoKRA"
                      target="_blank"
                      class="white red--text"
                      elevation="2"
                    ><v-icon>mdi-youtube</v-icon></v-btn>
                  </div>
                  <div class="ml-5">
                    <v-btn
                      href="https://www.linkedin.com/in/garrisonmcmullen"
                      target="_blank"
                      class="white blue--text text--darken-3"
                      elevation="2"
                    ><v-icon>mdi-linkedin</v-icon></v-btn>
                  </div>
                  <div class="mr-auto">
                    <!--  -->
                  </div>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-main>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import WebGL from './components/WebGL';

export default {
  name: 'App',

  components: {
    WebGL
  },

  data: () => ({
    tab: 'home',
    month: (new Date()).getMonth(),
  }),
};
</script>

<style>

.v-application .primary--text, 
.v-tabs-slider,
.v-tab
{
  color: #b1ccaa !important;
  caret-color: #b1ccaa !important;
}

a {
  text-decoration: none;
}

header { 
  height: 84px!important;
}

.v-toolbar__content {
  height: 70px!important;
}

.v-toolbar__title { 
  overflow: visible!important;
}

.container__maxWidth { 
  max-width: 950px!important;
}

.text-transparent { 
  opacity: 0.3;
}

.text-inner-shadow {
   margin-right: 21px;
   text-shadow: 2px 2px 2px #3d313f;
}

.grid__item {
  opacity: 1.0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3d313f;
  min-height: 200px;
  font-size: 5em;
  color: inherit;
  text-decoration: none;
}

.grid__item:hover{ 
  opacity: 0.5;
  transition: opacity 0.125s ease-in-out;
}

/* .grid__item:focus + .grid__description { 
  max-height: 100vh;
  transition: max-height 0.65s ease-in-out;
  transition-delay: 0.55s;
} */

.helpbox { 
  opacity: 0.5;
  text-decoration: none;
}

.helpbox:focus, .helpbox:hover, helpbox:focus-within { 
  opacity: 1.0;
  transition: opacity 0.125s ease-in-out;
}

.helpbox__description { 
  max-height: 0;
  margin-left: 5%;
  margin-right: 5%;
  overflow: hidden;
  transition: max-height 0.4s ease-in;
}

/* .helpbox__container:focus-within > .helpbox__description {  */
/* .helpbox__description:hover, */
.helpbox:focus + .helpbox__description {
  max-height: 100rem;
  transition: max-height 0.65s ease-in-out;
  transition-delay: 0.55s;
}

.helpbox__paragraph { 
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.grid__description {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in;
}

.grid__description .container {
  padding: 2em;
}

@media screen and (min-width: 30em) {
  .grid {
    display: grid;
    grid-template-areas:
      "item item item"
      "desc desc desc";
    grid-template-columns: 33.33% 33.33% 33.33%;
  }

  .grid__description {
    grid-area: desc;
  }

  .grid__item:focus + .grid__description { 
    max-height: 100vh;
  }
}

@media screen and (min-width: 600px) { 
  .helpbox__description { 
    margin-left: 10%;
    margin-right: 10%;
  }
}

</style>
